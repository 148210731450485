import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from 'axios';
import { AuthService } from './auth-service';

export type ApiResponseErrors<T> = {
  [K in keyof T]?: string;
} & {
  submit?: string;
};

export class ApiError extends Error {
  constructor(readonly message: string, readonly code: number) {
    super(message);
    this.name = 'ApiError';
  }
}

export enum errors {
  BAD_REQUEST = 400,
}

export class ApiService {
  public axios: AxiosInstance;
  private static instance: ApiService;

  public constructor(config?: AxiosRequestConfig) {
    this.axios = axios.create(config);

    if (typeof window !== 'undefined' && AuthService) {
      const token = AuthService.getBrowserToken();
      if (token) this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(config?: AxiosRequestConfig): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService(config);
    }

    return ApiService.instance;
  }

  public setAuthorizationHeaders(token: string) {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  private handleErrors<E>(error: any): ApiResponseErrors<E> {
    let messages: ApiResponseErrors<E> = {
      submit: 'Something went wrong. Try again later.',
    };

    if (error && error.response) {
      const { data, status } = (error as AxiosError).response || {};

      switch (status) {
        case 400:
          messages = {
            submit: data.message,
          };
          break;

        case 404:
          messages = {
            submit: data.message,
          };
          break;

        case 422: {
          const _errors = {};
          const errors = data.detail || [];

          errors.map(({ loc, msg }) => {
            _errors[loc] = msg;
          });

          messages = _errors;
          break;
        }

        case 500: {
          messages = {
            submit: data.message,
          };
          break;
        }
        default:
          messages = {
            submit: 'Could not process your request at this time. Try again later',
          };
          break;
      }

      /* if (errors.constructor.toString().indexOf('Array') > -1) {
        const _errors = {};

        errors.map(({ location, message }) => {
          _errors[location] = message;
        });

        messages = _errors;
      } else {
        let msg = data.detail;

        if (status === 404) {
          msg = 'Could not process your request at this time. Try again later.';
        }

        if (status === 400) {
          msg = data.message;
        }

        messages = {
          submit: msg,
        };
      } */
    } else {
      messages = {
        submit: 'Could not process your request at this time. Try again after some time',
      };
    }

    return messages;
  }

  /**
   * HTTP POST method `statusCode`: 201 Created.
   *
   * @access public
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @template E - `ERROR`: expected object inside a axios error format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof ApiService
   */
  async post<B, R, E>(url: string, data?: B): Promise<R> {
    try {
      const response = await this.axios.post<R>(url, data);
      return response.data;
    } catch (error) {
      throw new ApiError(JSON.stringify(this.handleErrors(error)), errors.BAD_REQUEST);
    }
  }

  /**
   * HTTP PUT method `statusCode`: 200 Created.
   *
   * @access public
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @template E - `ERROR`: expected object inside a axios error format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof ApiService
   */
  async put<B, R, E>(url: string, data?: B): Promise<R> {
    try {
      const response = await this.axios.put<R>(url, data);
      return response.data;
    } catch (error) {
      throw new ApiError(JSON.stringify(this.handleErrors(error)), errors.BAD_REQUEST);
    }
  }

  /**
   * HTTP GET method, used to fetch data `statusCode`: 200.
   *
   * @access public
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @returns {Promise<R>} HTTP `axios` response data.
   * @memberof ApiService
   */
  async get<R>(url: string): Promise<R> {
    const response = await this.axios.get<R>(url);
    return response.data;
  }
}

// const isDev = process.env.NODE_ENV === 'development';
// const BASE_URL = isDev ? 'http://localhost:8000/api/v1.0' : 'https://nrr-dev.l2t.me/api/v1.0';
const BASE_URL = process.env.NEXT_PUBLIC_API_SERVER_URL;

export const apiService = ApiService.getInstance({
  baseURL: BASE_URL,
  headers: {},
});
