import Cookie from 'js-cookie';
import Router from 'next/router';
import jwtDecode from 'jwt-decode';
import { IncomingMessage } from 'http';
import { NextPageContext } from 'next';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

import { apiService } from './api-service';

const TOKEN_STORAGE_KEY = 'authToken';
const SESSION_TOKEN_KEY = 'sessionToken';
const SECRET_KEY = 'NWEi57rSnFFypTG7ZI25Kdz9tyvpRMrL5E';

export type DecodedToken = {
  readonly user_id: string;
  readonly exp: number;
};

export class AuthToken {
  readonly decodedToken: DecodedToken;

  constructor(readonly token?: string) {
    this.decodedToken = { user_id: '', exp: 0 }; //eslint-disable-line @typescript-eslint/camelcase

    try {
      if (token) this.decodedToken = jwtDecode(token);
    } catch (e) {}
  }

  get expiresAt(): Date {
    return new Date(this.decodedToken.exp * 1000);
  }

  get isExpired(): boolean {
    return new Date() > this.expiresAt;
  }

  get isValid(): boolean {
    return !this.isExpired;
  }

  get authorizationString() {
    return `Bearer ${this.token}`;
  }
}

export class AuthService {
  token = new AuthToken();

  constructor(readonly req?: IncomingMessage) {}

  private getCookiesFromReq = (req: IncomingMessage): Record<string, string | undefined> => {
    const _cookies = {};
    const rhc = req.headers.cookie;

    rhc
      ? rhc.split(';').forEach((cookie) => {
          const parts = cookie.split('=');
          _cookies[parts.shift().trim()] = decodeURI(parts.join('='));
        })
      : null;

    return _cookies;
  };

  static async verifyPhoneNo(userId: string, phoneNumber: string, redirectPath: string) {
    const query = {
      userId,
      phoneNumber,
    };

    const redirected = await Router.replace({
      pathname: redirectPath,
      query: {
        token: CryptoJS.AES.encrypt(JSON.stringify(query), SECRET_KEY).toString(),
      },
    });

    if (!redirected) {
      await Router.replace({
        pathname: redirectPath,
        query: {
          token: CryptoJS.AES.encrypt(JSON.stringify(query), SECRET_KEY).toString(),
        },
      });
    }
  }

  static getBrowserToken(): string {
    return Cookie.get(TOKEN_STORAGE_KEY);
  }

  static decodeToken(token: string) {
    try {
      const { user_id: userId } = jwtDecode(token);
      return userId;
    } catch (error) {
      return undefined;
    }
  }

  static async saveToken(token: string, redirect = true) {
    Cookie.set(TOKEN_STORAGE_KEY, token);
    apiService.setAuthorizationHeaders(token);

    AuthService.saveSessionToken(AuthService.decodeToken(token));

    if (redirect) await Router.replace('/dashboard');
  }

  static async signin() {
    Cookie.remove(TOKEN_STORAGE_KEY);
    apiService.setAuthorizationHeaders(undefined);

    await Router.push('/signin');
  }

  static saveSessionToken(_userId?: string) {
    const { sessionId = uuidv4(), userId = _userId } = JSON.parse(
      Cookie.get(SESSION_TOKEN_KEY) || '{}',
    );

    Cookie.set(
      SESSION_TOKEN_KEY,
      JSON.stringify({
        userId,
        sessionId,
      }),
    );

    return { sessionId, userId };
  }

  static async deleteToken() {
    Cookie.remove(TOKEN_STORAGE_KEY);
    Cookie.remove(SESSION_TOKEN_KEY);
    apiService.setAuthorizationHeaders(undefined);
    await Router.replace('/');
    Router.reload();
  }

  static async resetPassword(userId: string, phoneNumber: string) {
    const query = {
      userId,
      phoneNumber,
      resetPassword: true,
    };

    await Router.push({
      pathname: '/reset-password',
      query: {
        token: CryptoJS.AES.encrypt(JSON.stringify(query), SECRET_KEY).toString(),
      },
    });
  }

  static decryptVerificationToken = (ciphertext: string) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(originalText);
  };

  getAuthToken = () => {
    if (typeof window === 'undefined') {
      const { authToken = '' } = this.getCookiesFromReq(this.req);
      return authToken;
    } else {
      return Cookie.get('authToken');
    }
  };

  get isAuthenticated(): boolean {
    const token = new AuthToken(this.getAuthToken());
    apiService.setAuthorizationHeaders(token.token);
    return token.isValid;
  }

  currentRoute = (ctx: NextPageContext) => {
    return ctx.res ? ctx.req.url : Router.route;
  };

  redirect = (ctx: NextPageContext, redirectRoute: string) => {
    if (ctx.res) {
      ctx.res.writeHead(302, {
        Location: redirectRoute,
      });

      ctx.res.end();
    } else {
      Router.push(redirectRoute);
    }
  };
}
